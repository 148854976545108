'use client';

import { useIntercom } from 'react-use-intercom';
import { useTranslations } from 'next-intl';

import { Button } from '@zealy/design-system';
import { ArrowRightLine } from '@zealy/icons';

export const HelpButton = () => {
  const t = useTranslations('error');

  const intercom = useIntercom();

  return (
    <Button
      variant="ghost"
      size="xs"
      rightIcon={<ArrowRightLine />}
      onClick={intercom.show}
      className="self-end"
    >
      {t('help')}
    </Button>
  );
};
