'use client';

import React, { useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';

import type { User } from '@zealy/queries';
import type { IAuthError } from '@zealy/utils';
import { Alert, Link } from '@zealy/design-system';
import { useUser } from '@zealy/queries';

import { useAuthError } from '#context/AuthError';

import { HelpButton } from './HelpButton';

export interface AuthErrorAlertProps extends IAuthError {
  className?: string;
  data: {
    userId?: string;
    accountType?: IAuthError['data']['accountType'];
    userDeletedAt?: string;
  };
}

export const AuthErrorAlert = ({ name, data, className }: AuthErrorAlertProps) => {
  const { setAuthError } = useAuthError();
  const t = useTranslations('error');
  const ref = useRef<HTMLDivElement>(null);

  const user = useUser<User>(data?.userId ?? '');

  useEffect(() => {
    ref.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, []);

  return (
    <Alert
      variant="error"
      ref={ref}
      title={t(`auth.${name}.title`)}
      description={t.rich(`auth.${name}.description`, {
        ...data,
        accountType: data?.accountType ?? '',
        name: user.data?.name ?? '',
        signup: chunk => (
          <Link as={NextLink} underlined href="/signup" onClick={() => setAuthError(undefined)}>
            {chunk}
          </Link>
        ),
      })}
      actions={<HelpButton />}
      className={className}
    />
  );
};
